export const getStyleProps = type => {
  const COLORS_MAP = {
    facebook: {
      color: 'white',
      bg: '#1877F2',
      '&:hover': {
        bg: '#0C63D4',
      },
    },
    twitter: {
      bg: '#1DA1F2',
      color: 'white',
      '&:hover': {
        bg: '#0C8BD9',
      },
    },
    dribble: {
      bg: '#EA4C89',
      color: 'white',
      '&:hover': {
        bg: '#E62872',
      },
    },
    black: {
      bg: 'black',
      color: 'white',
    },
  }
  if (COLORS_MAP[type]) {
    return COLORS_MAP[type]
  }

  return {}
}
