import { useMemo } from "react";
import PropTypes from "prop-types";

import { getStyleProps } from "./utils";

import { SocialLinkText, SocialLinkWrapper } from "./components";
import { twMerge } from "tailwind-merge";

const SocialLink = ({
  variant,
  title,
  iconComponent: IconComponent,
  iconColor,
  colorType,
  className = "",
  ...props
}) => {
  // replace this import colors

  const paddingProps = useMemo(() => {
    if (!title) return "p-2.5";
    return "py-2.5 px-4";
  }, [title]);

  return (
    <SocialLinkWrapper
      as="button"
      variant={variant}
      style={{
        ...getStyleProps(colorType),
      }}
      className={twMerge(
        "cursor-pointer text-white w-fit rounded-4 focus-within:shadow-md",
        className,
        paddingProps,
      )}
      {...props}
    >
      <>
        <IconComponent color={iconColor} />
        {title && (
          <SocialLinkText
            variant={variant}
            className="font-body ml-3 font-medium leading-3 text-base"
          >
            {title}
          </SocialLinkText>
        )}
      </>
    </SocialLinkWrapper>
  );
};

SocialLink.displayName = "SocialLinks";

SocialLink.propTypes = {
  iconComponent: PropTypes.elementType,
  title: PropTypes.string,
  variant: PropTypes.string,
  iconColor: PropTypes.string,
  colorType: PropTypes.string,
  className: PropTypes.string,
};

export default SocialLink;
