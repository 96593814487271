import Flex from "sholdi-primitives/atoms/Flex";
import Text from "sholdi-primitives/atoms/Text";
import { twMerge } from "tailwind-merge";
import PropTypes from "prop-types";

const useSocialLinkStyles = (variant) => {
  const baseStyles = "bg-white border px border-gray-300";
  const hoverStyles = "hover:bg-gray-50";

  const styles = {
    gray: twMerge(baseStyles, "text-gray-700", hoverStyles),
    grayIcon: twMerge(baseStyles, hoverStyles),
  };

  return styles[variant] || baseStyles;
};

// SocialLinkWrapper component
export const SocialLinkWrapper = ({
  variant,
  children,
  className = "",
  ...props
}) => {
  const baseClasses = useSocialLinkStyles(variant);
  return (
    <Flex className={twMerge(baseClasses, className)} {...props}>
      {children}
    </Flex>
  );
};

SocialLinkWrapper.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.element,
  className: PropTypes.string,
};

// SocialLinkText component
export const SocialLinkText = ({
  variant,
  children,
  className = "",
  ...props
}) => {
  const textColor = variant === "gray" ? "text-gray-700" : "";
  return (
    <Text className={twMerge(textColor, className)} {...props}>
      {children}
    </Text>
  );
};

SocialLinkText.propTypes = {
  variant: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
};
