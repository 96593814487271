import PropTypes from "prop-types";
import { signIn } from "next-auth/react";

import useTranslation from "sholdi-hooks/useTranslation";

import isChromeExtension from "sholdi-shared/helpers/isChromeExtension";

import GoogleIcon from "sholdi-icons/GoogleIcon";
import FacebookIcon from "sholdi-icons/FacebookIcon";
import Text from "sholdi-primitives/atoms/Text";
import Link from "sholdi-primitives/atoms/Link";

import SocialLink from "../SocialLinks/SocialLink";
import LoadingButton from "../LoadingButton";

function AuthActions({ label, loading, isRegisterPage, isShopRegisterPage }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex-col mt-8">
        <LoadingButton
          loading={loading}
          variant="primary"
          type="submit"
          className="w-full"
        >
          {t(label)}
        </LoadingButton>
        <SocialLink
          type="button"
          iconComponent={GoogleIcon}
          title={t("form.googleSignIn")}
          variant="gray"
          onClick={async () => await signIn("google")}
          className="flex justify-center w-full mt-4"
        />

        <SocialLink
          type="button"
          iconComponent={FacebookIcon}
          title={t("form.facebookSignIn")}
          variant="gray"
          onClick={async () => await signIn("facebook")}
          className="flex justify-center w-full mt-4"
        />
      </div>
      <div className="flex flex-col items-center mt-8 w-full">
        {!isChromeExtension() && isShopRegisterPage && (
          <Link
            href="/waitlist/signup"
            className="w-full p-2 mb-4 text-primary-dark bg-primary-lightest rounded-sm font-medium text-center"
          >
            {t("waitlistSignup")}
          </Link>
        )}
        {!isChromeExtension() ? (
          isRegisterPage ? (
            <div className="flex leading-1.5 gap-2 pb-8">
              <Text>{t("form.accountAlreadyExists")}</Text>
              <Link href="/login" className="font-medium text-primary-dark">
                {t("form.signUp")}
              </Link>
            </div>
          ) : (
            <div className="flex leading-1.5 gap-2">
              <Text>{t("noSholdi")}</Text>
              <Link
                href="/register"
                className="ml-2 font-medium text-primary-dark"
              >
                {t("register")}
              </Link>
            </div>
          )
        ) : null}
      </div>
    </>
  );
}

AuthActions.propTypes = {
  label: PropTypes.string,
  loading: PropTypes.bool,
  isRegisterPage: PropTypes.bool,
  isShopRegisterPage: PropTypes.bool,
};

export default AuthActions;
